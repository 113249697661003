import { UserCourseStatusEnum } from "@/outscal-commons-frontend/Enums";
import { FLOWTYPES } from "../UserFlowService/UserFlowService.types";
import ClientAppManagerInterface from "../ClientAppManagerInterface";

class BasePageManager<T, J extends ClientAppManagerInterface> {
  private isInitialized: boolean;
  public pageData: T;
  protected clientAppManager: J;

  public constructor(pageData: T) {
    this.isInitialized = false;
    this.pageData = pageData;
  }

  public setClientAppManager(clientAppManager: J) {
    this.clientAppManager = clientAppManager;
  }

  public userDetailsInitialised() {
    const userCourseStatuses =
      this.clientAppManager?.userDetailsManager?.user?.userCourseStatuses;

    if (
      userCourseStatuses?.find(
        (status) =>
          status.current_status?.status ==
          UserCourseStatusEnum.getValue("waitlisted")
      )
    ) {
      this.clientAppManager.userFlowManager?.checkAndTriggerFlow(
        FLOWTYPES.RETURNING_JW_USER
      );
    }
  }

  setPageData(): void {
    this.initialize();
  }

  public initialize(): boolean {
    if (this.isInitialized) {
      return false;
    }
    this.isInitialized = true;
    return true;
  }

  public pageMounted(): void {}

  public reset(): void {
    this.isInitialized = false;
    console.log("App manager reset");
  }
}

export default BasePageManager;
